<template>
  <div>
    <b-card-code title="Add Area To Courier">
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col sm="12">
              <div>
                <!-- Row Loop -->
                <b-row
                  v-for="(item, index) in courierData.areas"
                  :id="item.id"
                  :key="item.id"
                  ref="row"
                >
                  <!-- country -->
                  <b-col md="4">
                    <validation-provider
                      #default="{ errors }"
                      name="Country"
                      rules="required"
                    >
                      <b-form-group
                        label="Country"
                        label-for="country"
                        :state="errors.length > 0 ? false : null"
                      >
                        <v-select
                          id="country"
                          v-model.lazy="countrySelect[index]"
                          v-on:input="countrySelected(index)"
                          :state="countrySelect[index] == null ? false : true"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="countryOptions"
                          :selectable="
                            (option) => !option.value.includes('select_value')
                          "
                          label="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!-- city -->
                  <b-col md="4">
                    <validation-provider
                      #default="{ errors }"
                      name=' " city " + [index] '
                      rules="required"
                    >
                      <b-form-group
                        label="City"
                        label-for="city"
                        :state="errors.length > 0 ? false : null"
                      >
                        <v-select
                          :id="' city ' + [index]"
                          v-model="citySelect[index]"
                          @input="citySelected(index)"
                          :disabled="city[index].disable"
                          :state="citySelect[index] == null ? false : true"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="cityOptions[index]"
                          :selectable="
                            (option) => !option.value.includes('select_value')
                          "
                          label="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!-- area -->
                  <b-col md="4">
                    <validation-provider
                      #default="{ errors }"
                      name="Area"
                      rules="required"
                    >
                      <b-form-group
                        label="Area"
                        label-for="area"
                        :state="errors.length > 0 ? false : null"
                      >
                        <v-select
                          id="area"
                          v-model="areaSelect[index]"
                          :disabled="area[index].disable"
                          :state="areaSelect[index] == null ? false : true"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="areaOptions[index]"
                          :selectable="
                            (option) => !option.value.includes('select_value')
                          "
                          label="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col md="4">
                    <b-form-group label="Price" label-for="price">
                      <validation-provider
                        #default="{ errors }"
                        name="price"
                        rules="required|regex:\b\d+(?:\.\d\d?)?\b"
                      >
                        <b-form-input
                          id="price"
                          v-model="item.price"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Remove Button -->
                  <b-col lg="4" md="4" class="mb-50">
                    <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      class="mt-0 mt-md-2"
                      @click="removeItem(index)"
                    >
                      <feather-icon icon="XIcon" class="mr-25" />
                      <span>Remove</span>
                    </b-button>
                  </b-col>
                </b-row>
              </div>
            </b-col>
            <!-- add new button -->
            <b-col sm="2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-primary"
                block
                @click="repeateAgain"
              >
                <feather-icon icon="PlusIcon" class="mr-25" />
                <span>Add New</span>
              </b-button>
            </b-col>
            <b-col cols="12">
              <hr />
            </b-col>
            <!--submit button -->
            <b-col>
              <b-button
                size="lg"
                variant="primary"
                type="submit"
                @click.prevent="validationForm"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card-code>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">
        Alert
      </h4>
      <div class="alert-body" v-if="errors_back != null">
        <span>{{ errors_back }}</span>
      </div>
    </b-alert>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import { heightTransition } from '@core/mixins/ui/transition'
import { required, integer } from '@validations'


export default {
  components: {
    BCardCode,
  },

  mixins: [heightTransition],
  data() {
    return {
      showDismissibleAlert: false,
      id: 0,
      area: [
        {
          disable: true,
        },
      ],
      city: [
        {
          disable: true,
        },
      ],
      countryOptions: [
        {
          value: 'select_value',
          text: 'Select Value',
        },
      ],
      countrySelect: [null],
      cityOptions: [
        [
          {
            value: 'select_value',
            text: 'Select Value',
          },
        ],
      ],
      citySelect: [null],
      areaOptions: [[]],
      areaSelect: [null],
      courierData: {
        areas: [
          {
            area_id: '',
            price: '',
          },
        ],
      },
      errors_back: [],
      required,
      integer,
      countryIndex: null,
    }
  },
  watch: {},
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          for (let ind in this.areaSelect) {
            this.courierData.areas[ind].area_id = this.areaSelect[ind].value
          }
          for (let index in this.courierData.areas) {
            delete this.courierData.areas[index].city
            delete this.courierData.areas[index].city_id
            delete this.courierData.areas[index].id
            delete this.courierData.areas[index].name
          }
          axios
            .post('couriers/' + this.id + '/areas/sync ', this.courierData)
            .then((result) => {
              this.$swal({
                position: 'center',
                icon: 'success',
                title: 'Your work has been saved',
                showConfirmButton: false,
                timer: 1500,
              })
              this.$router.back(1)
            })
            .catch((err) => {
              this.$swal({
                position: 'center',
                icon: 'error',
                title: 'Error!',
                showConfirmButton: false,
                timer: 1500,
              })
              this.errors_back.length = 0
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  })
                } else {
                  this.errors_back = err.response.data.data
                }
                this.showDismissibleAlert = true
              } else {
                this.errors_back = []
                this.errors_back.push({
                  error: 'internal server error',
                })
                this.showDismissibleAlert = true
              }
            })
        }
      })
    },
    // REPETED FORM
    repeateAgain() {
      this.courierData.areas.push({
        //  id: this.nextTodoId += this.nextTodoId,
      })
      this.citySelect.push(null)
      this.countrySelect.push(null)
      this.areaSelect.push(null)
      this.cityOptions.push([
        {
          value: 'select_value',
          text: 'Select Value',
        },
      ])
      this.areaOptions.push([])
      this.city.push({
        disable: true,
      })
      this.area.push({
        disable: true,
      })
    },
    removeItem(index) {
      if (this.courierData.areas.length <= 1) {
        this.$bvModal.msgBoxConfirm('You need minmum one tab to continue.', {
          title: 'Alert',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Accept',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
      } else {
        this.courierData.areas.splice(index, 1)
        this.citySelect.splice(index, 1)
        this.countrySelect.splice(index, 1)
        this.areaSelect.splice(index, 1)
        this.areaOptions.splice(index, 1)
        this.cityOptions.splice(index, 1)
        this.area.splice(index, 1)
        this.city.splice(index, 1)
      }
    },
    initTrHeight() {
      this.trSetHeight(null)
    },
    countrySelected(indx) {
      axios
        .get('country/' + this.countrySelect[indx].value + '/cities')
        .then((result) => {
          const data = result.data.data
          this.city[indx].disable = false
          this.cityOptions[indx].length = 0
          for (let ind in data) {
            this.cityOptions[indx].push({
              text: data[ind].name,
              value: data[ind].id.toString(),
            })
          }
          this.$emit('cityOptions', this.cityOptions)
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    citySelected(indx) {
      axios
        .get('cities/' + this.citySelect[indx].value + '/areas')
        .then((result) => {
          const data = result.data.data
          this.area[indx].disable = false
          this.areaOptions[indx].length = 0
          for (let ind in data) {
            this.areaOptions[indx].push({
              text: data[ind].name,
              value: data[ind].id.toString(),
            })
          }
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
  },
  created() {
    this.id = this.$route.params.id
    axios
      .get('countries')
      .then((result) => {
        const data = result.data.data
        for (let ind in data) {
          this.countryOptions.push({
            text: data[ind].name,
            value: data[ind].id.toString(),
          })
        }
      })
      .catch((err) => {
        this.errors_back.length = 0
        if (err.response.data.data != null) {
          if (this.isString(err.response.data.data)) {
            this.errors_back.push({
              error: err.response.data.data,
            })
          } else {
            this.errors_back = err.response.data.data
          }
          this.showDismissibleAlert = true
        } else {
          this.errors_back = []
          this.errors_back.push({
            error: 'internal server error',
          })
          this.showDismissibleAlert = true
        }
      })
    axios
      .get('couriers/' + this.id)
      .then((result) => {
        const data = result.data.data
        this.courierData = data
        this.areas = this.courierData.areas
        this.countrySelect.length = this.areas.length
        this.citySelect.length = this.areas.length
        this.areaSelect.length = this.areas.length
        this.area.length = this.areas.length
        this.city.length = this.areas.length
        this.cityOptions.length = this.areas.length
        this.areaOptions.length = this.areas.length
        for (let ind in this.areas) {
          const country = this.areas[ind].city.country
          const city = this.areas[ind].city
          this.countrySelect[ind] = {
            text: '',
            value: '',
          }
          this.citySelect[ind] = {
            text: '',
            value: '',
          }
          this.areaSelect[ind] = {
            text: '',
            value: '',
          }
          this.area[ind] = {
            disable: false,
          }
          this.city[ind] = {
            disable: false,
          }
          this.countrySelect[ind].text = country.name
          this.countrySelect[ind].value = country.id.toString()
          this.citySelect[ind].text = city.name
          this.citySelect[ind].value = city.id.toString()
          this.areaSelect[ind].text = this.areas[ind].name
          this.areaSelect[ind].value = this.areas[ind].id.toString()
          axios
            .get('country/' + country.id.toString() + '/cities')
            .then((result) => {
              const data = result.data.data
              for (let cit in data) {
                this.cityOptions[ind].push({
                  text: data[cit].name,
                  value: data[cit].id.toString(),
                })
              }
              this.$emit('cityOptions', this.cityOptions)
            })
            .catch((err) => {
              this.errors_back.length = 0
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  })
                } else {
                  this.errors_back = err.response.data.data
                }
                this.showDismissibleAlert = true
              } else {
                this.errors_back = []
                this.errors_back.push({
                  error: 'internal server error',
                })
                this.showDismissibleAlert = true
              }
            })
          axios
            .get('cities/' + city.id.toString() + '/areas')
            .then((result) => {
              const data = result.data.data
              // this.areaOptions[ind].length = 0
              for (let are in data) {
                this.areaOptions[ind].push({
                  text: data[are].name,
                  value: data[are].id.toString(),
                })
              }
            })
            .catch((err) => {
              this.errors_back.length = 0
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  })
                } else {
                  this.errors_back = err.response.data.data
                }
                this.showDismissibleAlert = true
              } else {
                this.errors_back = []
                this.errors_back.push({
                  error: 'internal server error',
                })
                this.showDismissibleAlert = true
              }
            })
        }
      })
      .catch((err) => {
        this.errors_back.length = 0
        if (err.response.data.data != null) {
          if (this.isString(err.response.data.data)) {
            this.errors_back.push({
              error: err.response.data.data,
            })
          } else {
            this.errors_back = err.response.data.data
          }
          this.showDismissibleAlert = true
        } else {
          this.errors_back = []
          this.errors_back.push({
            error: 'internal server error',
          })
          this.showDismissibleAlert = true
        }
      })
  },
  isString(value) {
    return typeof value === 'string' || value instanceof String
  },
}
</script>

<style lang="scss">

</style>
